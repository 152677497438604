<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllContractsLight.gql')"
    :variables="{}"
    @result="doneLoadingContracts"
  >
    <template slot-scope="{ result: { error, data } }" class="template-form">
      <div v-if="loadingData && !error" class="messages__status loading">Идёт загрузка данных...</div>
      <div v-if="error" class="messages__status error">Ошибка. Обратитесь в тех.поддержку</div>
      <div v-if="!error && !loadingData" class="template-form">
          <p>Прошу ООО «Лизинг-Трейд» рассмотреть возможность замены Лизингополучателя по Договору лизинга №
            <select @change="inputTrigger" v-model="selectContractModel" id="contract">
              <option v-for="item in data.allContracts" :key="item.name" :value="item.name">{{ item.name }}</option>
            </select>
            от <input @change="inputTrigger" type="date" v-model="dateContract" /> в связи c</p>
          <p><input @change="inputTrigger" v-model="cause" type="text" placeholder="Подробное описание причины необходимости замены Лизингополучателя"></p>
          <p>Данные Нового лизингополучателя:</p>
          <p>ФИО: <input @change="inputTrigger" v-model="name" type="text" placeholder="Иванов Иван Иванович"></p>
          <p>Паспортные данные: <input @change="inputTrigger" v-model="passport" type="text" placeholder="Серия и номер"></p>
          <p>ИНН: <input @change="inputTrigger" v-model="inn" type="text"></p>
          <p>СНИЛС: <input @change="inputTrigger" v-model="snils" type="text"></p>
          <p>Так же дополнительно сообщаем вам, что (ФИО) не является аффилированным или иным образом взаимосвязанным лицом по отношению к лизингополучателю.</p>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
export default {
  name: 'Template5',
  data () {
    return {
      selectContract: '',
      dateContract: '',
      allContracts: [],
      cause: '',
      name: '',
      passport: '',
      inn: '',
      snils: '',
      loadingData: true
    }
  },
  beforeDestroy () {
    this.$store.state.faqMessage = ''
  },
  methods: {
    inputTrigger () {
      const result = `
        Прошу ООО «Лизинг-Трейд» рассмотреть возможность замены Лизингополучателя по Договору лизинга № ${this.selectContract} от ${this.dateContract} в связи с ${this.cause} (подробное описание причины необходимости замены Лизингополучателя)

        Данные Нового лизингополучателя:

        - ФИО: ${this.name}
        - Паспортные данные: ${this.passport}
        - ИНН: ${this.inn}
        - СНИЛС: ${this.snils}

        Так же дополнительно сообщаем вам, что (ФИО) не является аффилированным или иным образом взаимосвязанным лицом по отношению к лизингополучателю.
      `
      this.$store.state.faqMessage = result
    },
    checkInputs () {
      if (this.selectContract && this.dateContract && this.cause && this.name && this.passport && this.inn && this.snils) return true
      else return false
    },
    doneLoadingContracts ({ data }) {
      this.allContracts = data.allContracts
      this.loadingData = false
    }
  },
  computed: {
    selectContractModel: {
      get () {
        return this.selectContract
      },
      set (value) {
        this.selectContract = value
        this.allContracts.map(elem => {
          if (elem.name === value) this.dateContract = elem.date.split('T')[0]
        })
      }
    }
  }
}
</script>

<style lang="stylus">
  .template-form {
    display inline-block
    white-space pre-wrap

  > p {
      > input[type='date'] {
        width 150px !important
      }
      > input[type='number'] {
        width 60px !important
      }
      > .base {
        width 120px !important
      }
    }
  }
</style>
