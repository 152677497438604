<template>
  <div class="lsg-faq">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__button">
      <router-link :to="{ name: 'faq' }" class="btn">ВОПРОСЫ И ОТВЕТЫ</router-link>
    </div>
    <div class="main__content">
      <form class="lk" autocomplete="off">
        <div class="lk__inputs">
          <div class="lk__field">
            <label for="subject">Тема обращения:</label>
            <select
              v-if="['partner', 'callcenter', 'admin'].includes($store.state.me.role)"
              v-model="modelTheme" id="subject"
            >
              <option selected disabled>Выберите тему</option>
              <option
                v-for="(item, index) in $store.state.allThemes"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              v-else
              v-model="modelTheme" id="subject"
            >
              <option selected disabled>Выберите тему</option>
              <option
                :value="$store.state.allThemes[$store.state.allThemes.length - 1].id"
              >
                {{ $store.state.allThemes[$store.state.allThemes.length - 1].name }}
              </option>
            </select>
          </div>
          <div class="lk__field mb-0">
            <label @click="checkMessage" for="message">Содержание обращения:</label>
            <div class="template-form" v-if="theme === 14">
              <span>Укажите в сообщении номер договора, вид документа, период, электронную почту, контактный номер телефона, ЭДО (СБИС или Диадок), с подписью или без</span>
            </div>
            <Template1 ref="template1" v-if="theme === 1" />
            <Template2 ref="template2" v-else-if="theme === 2" />
            <Template3 ref="template3" v-else-if="theme === 3" />
            <Template4 ref="template4" v-else-if="theme === 4" />
            <Template5 ref="template5" v-else-if="theme === 5" />
            <Template6 ref="template6" v-else-if="theme === 6" />
            <Template7 ref="template7" v-else-if="theme > 6 && theme < $store.state.allThemes.length && theme !== 14" />
            <div class="input-form">
              <textarea
                v-model="modelTextarea"
                id="message"
                placeholder="Комментарий"
              ></textarea>
              <div class="dropzone-container">
                <vue-dropzone
                  id='dropzone'
                  ref='dropzone'
                  @vdropzone-files-added="filesPicked"
                  @vdropzone-upload-progress="fileProgress"
                  @vdropzone-error="fileError"
                  @vdropzone-removed-file="fileRemove"
                  @vdropzone-canceled="fileCancel"
                  @vdropzone-queue-complete="queueComplete"
                  :includeStyling="false"
                  useCustomSlot
                  :options="dropzoneOptions">
                  <div class="dropzone-icon">
                    <i>
                      <PaperClip/>
                    </i>
                  </div>
                </vue-dropzone>
              </div>
            </div>
            <div id="dz-previews" class="dz-previews"></div>
          </div>
          <i class="lk__field__help">Подсказка: количество отправляемых файлов: 4; максимальный размер файла: 2 Мб; доступные типы: .jpg, .jpeg, .pdf</i>
          <button v-if="activeButton" type="button" class="btn" @click="sendingRequest">ОТПРАВИТЬ ОБРАЩЕНИЕ</button>
          <button v-else type="button" class="btn btn--disable">ОТПРАВКА...</button>
          <!-- <button type="button" class="btn btn--disable">ОТПРАВИТЬ ОБРАЩЕНИЕ</button> -->
        </div>
        <div class="lk__back">
          <SupportBack/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SupportBack from '@/components/svg/SupportBack'
import PaperClip from '@/components/svg/PaperClip'
import vue2Dropzone from 'vue2-dropzone'
import ADD_SUPPORT_REQUEST from '@/graphql/mutations/addSupportRequest.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import Template1 from './templates/Template1.vue'
import Template2 from './templates/Template2.vue'
import Template3 from './templates/Template3.vue'
import Template4 from './templates/Template4.vue'
import Template5 from './templates/Template5.vue'
import Template6 from './templates/Template6.vue'
import Template7 from './templates/Template7.vue'

const getTemplate = () => `
<div class="dz-preview dz-file-preview">
  <div class="dz-details">
    <div class="dz-size"><span data-dz-size></span></div>
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <span class="dz-remove" data-dz-remove><a>Удалить</a></span>
</div>
`

const _graphQlHelper = new GraphQLHelper()

const BASE_URL = process.env.VUE_APP_HTTP

export default {
  name: 'Question',
  components: {
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
    Template6,
    Template7,
    // PlusIco,
    SupportBack,
    PaperClip,
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      dropzoneOptions: {
        previewTemplate: getTemplate(),
        url: 'https://httpbin.org/post',
        parallelUploads: 1,
        maxFiles: 4,
        maxFilesize: 2, // 2Mb
        acceptedFiles: 'image/jpg, image/jpeg, application/pdf',
        previewsContainer: '#dz-previews'
      },
      activeButton: true,
      files: [],
      select: 0,
      pattern: false,
      theme: 0,
      file: null,
      title: 'Поддержка',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Поддержка'
        }
      ],
      selectedThemeId: 'Выберите тему',
      textareaValue: ''
    }
  },
  beforeMount () {
    if (this.$route.query.theme && this.$store.state.allThemes) {
      this.$store.state.allThemes.forEach(theme => {
        if (theme.name === this.$route.query.theme) {
          this.modelTheme = theme.id
        }
      })
    }
  },
  methods: {
    queueComplete () {
      if (this.files.length > 0) {
        this.files.forEach(elem => {
          if (elem.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка при загрузке файлов'
            })
          } else {
            this.$notify({
              group: 'lsg-notify',
              text: 'Файлы загружены'
            })
          }
        })
      }
    },
    fileCancel (file) {
      this.$refs.dropzone.removeAllFiles()
      this.$notify({
        group: 'lsg-notify',
        text: `Отмена загрузки файла ${file.name}`
      })
    },
    fileRemove (file, error, xhr) {
      const updateFiles = []
      this.files.forEach(elem => {
        if (elem.upload.uuid !== file.upload.uuid) {
          updateFiles.push(elem)
        }
      })
      this.files = updateFiles
    },
    fileError (file, message, xhr) {
      file.previewElement.style.setProperty('background-color', '#FF0000')
      this.$notify({
        group: 'lsg-notify',
        text: 'Файл не может быть загружен'
      })
    },
    fileProgress (file, progress, bytesSent) {
      file.previewElement.style.setProperty('--progress', progress + '%')
    },
    filesPicked (e) {
      this.files = e
    },
    submitDropFile () {
      const url = `${BASE_URL}/files`
      let formData = new FormData()
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i]
        formData.append(file.upload.uuid, file)
      }
      axios.post(url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(() => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Файлы успешно отправлены'
        })
      }).catch(() => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Ошибка при отправке файлов'
        })
      })
    },
    checkMessage () {
      let templateInputs = true
      if (this.theme === 1) {
        templateInputs = this.$refs.template1.checkInputs()
      } else if (this.theme === 2) {
        templateInputs = this.$refs.template2.checkInputs()
      } else if (this.theme === 3) {
        templateInputs = this.$refs.template3.checkInputs()
      } else if (this.theme === 4) {
        templateInputs = this.$refs.template4.checkInputs()
      } else if (this.theme === 5) {
        templateInputs = this.$refs.template5.checkInputs()
      } else if (this.theme === 6) {
        templateInputs = this.$refs.template6.checkInputs()
      } else if (this.theme > 7 && this.theme < this.$store.state.allThemes.length && this.theme !== 14) {
        templateInputs = this.$refs.template7.checkInputs()
      }
      if (!templateInputs) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не все поля заполнены'
        })
        throw new Error('Не все поля заполнены')
      } else return true
    },
    async sendingRequest () {
      if (this.theme > 6 && this.textareaValue.replace(/\s+/g, '').replace('/[^a-zA-Z-]/g', '').toLowerCase() === '') {
        this.$notify({
          group: 'lsg-notify',
          text: 'Нет сообщения.'
        })
        throw new Error('Нет сообщения.')
      }
      const filesArr = []
      if (this.files.length > 0) {
        this.files.forEach(elem => {
          if (elem.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка. Повторите отправку, дождавшись загрузки всех файлов'
            })
            throw new Error('Ошибка. Повторите отправку, дождавшись загрузки всех файлов')
          } else {
            filesArr.push(elem.upload.uuid)
          }
        })
      }
      const checkMessage = this.checkMessage()
      if (checkMessage && this.selectedThemeId && this.selectedThemeId !== 'Выберите тему') {
        this.activeButton = false
        await this.$apollo.mutate({
          mutation: ADD_SUPPORT_REQUEST,
          variables: {
            theme_id: `${this.selectedThemeId}`,
            question: this.$store.state.faqMessage !== '' ? `${this.$store.state.faqMessage}\nКомментарий: ${this.textareaValue}` : this.textareaValue,
            question_files: this.files.length > 0 ? filesArr : null
          },
          update: (_, { data }) => {
            if (data) {
              this.activeButton = true
              try {
                if (this.files.length > 0) this.submitDropFile()
              } catch (e) {
                this.$notify({
                  group: 'lsg-notify',
                  text: e
                })
              }
              this.$store.state.faqNotification = true
              this.$router.push('support')
            }
          }
        }).catch(error => {
          this.activeButton = true
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
            throw new Error(error)
          })
        })
        this.$forceUpdate()
        this.selectedThemeId = 'Выберите тему'
        this.textareaValue = ''
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не выбрана тема.'
        })
        throw new Error('Не выбрана тема.')
      }
    }
  },
  computed: {
    modelTheme: {
      get () {
        return this.selectedThemeId
      },
      set (value) {
        this.selectedThemeId = value
        if (this.$store.state.allThemes) {
          this.$store.state.allThemes.forEach((elem, index) => {
            if (elem.id === this.selectedThemeId) {
              this.theme = index + 1
            }
          })
        }
      }
    },
    modelTextarea: {
      get () {
        return this.textareaValue
      },
      set (value) {
        this.textareaValue = value
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.btn--disable {
  background $c979797 !important
  color $white !important
  border-color $c979797 !important

  &:hover {
    background $c979797 !important
    cursor auto
  }

  &:active {
    transform none !important
  }
}

.lk__field {
  &+.mb-0 {
    margin-bottom 0 !important
  }

  &__help {
    display inline-block
    margin-bottom 15px
  }
}

.input-form {
  display flex
  flex-direction row
}

.upload-file
  opacity 0
  width 100%
  height 200px !important
  position absolute
  cursor pointer

.dropzone-container {
  min-width 60px
  min-height 60px
  margin 15px 0 0 10px
}

#dropzone {
  min-height 150px
  height 100%
}

.dropzone-icon {
  display flex
  justify-content center
  align-items center
  text-align center
  background: $white;
  border: 1px dashed $c4E555A;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left 10px
  max-width 350px
  width 60px
  height 100%
  cursor pointer

  &--full {
    width 100%
    margin-bottom 30px
  }

  i {
    width 22px
    height 22px
    display flex
    align-items center
    justify-content center
    margin-right 12px

    svg {
      width 100%
      height 100%
    }
  }
}
</style>

<style lang="stylus">
  #message {
    min-height 150px
    margin-top 15px
    font-size 13px !important
  }
  .message-header {
    display flex
    flex-direction row
    justify-content space-between
    margin-bottom 20px

    +below(450px) {
      flex-direction column
    }

    > label, .pattern {
      color $c7A
    }

    > .pattern > span {
      margin-left 10px
    }
  }
  .btn-create {
    width 100%
    display flex
    justify-content: flex-end
    margin-bottom 30px
  }
  .question-container {
    display flex
    flex-direction column
  }
  .question-item {
    display flex
    justify-content flex-start

    > span {
      display inline-block
      padding 10px
    }
  }
  .sup__answer {
    padding 0px 20px
  }
  .main__button {
    margin-top -55px
    padding 0px 20px 20px 20px
    display flex
    flex-direction row
    justify-content flex-end
    font-size 13px

    +below(600px) {
      display none
    }
  }

  #dropzone > .dz-message {
    display flex
    height 100%
  }

  #dz-previews {
    display flex
    flex-direction column
    margin-top 15px

    .dz-preview {
      position relative
      display flex !important
      flex-direction row !important
      padding 5px
      border 1px dashed $c4E555A
      border-radius 10px
      max-width 100% !important
      justify-content space-between
      z-index 2

      &:not(:last-child) {
        margin-bottom 10px
      }

      &::after {
        content: ''
        absolute left top
        background-color $anothergreen
        width var(--progress)
        opacity 0.5
        height 100%
        border-radius 10px
        z-index 1
      }
    }

    .dz-details {
      display flex !important
      flex-wrap nowrap !important
      flex-direction row-reverse !important
      z-index 2
      max-width 70%
    }

    .dz-filename {
      margin-right 10px
    }

    .dz-remove {
      z-index 2
      cursor: pointer
    }

    .dz-progress {
      position absolute
      top 0
      left 0
      background-color $green
      width 100%
    }

    .dz-upload {
      background-color $green
    }
  }
</style>
