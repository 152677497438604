<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllContractsLight.gql')"
    :variables="{}"
    @result="doneLoadingContracts"
  >
    <template slot-scope="{ result: { error, data } }" class="template-form">
      <div v-if="loadingData && !error" class="messages__status loading">Идёт загрузка данных...</div>
      <div v-if="error" class="messages__status error">Ошибка. Обратитесь в тех.поддержку</div>
      <div v-if="!error && !loadingData" class="template-form">
          <p>Прошу предоставить согласие на временное перемещение Предмета лизинга по Договору лизинга №
            <select @change="inputTrigger" v-model="selectContractModel" id="contract">
              <option v-for="item in data.allContracts" :key="item.name" :value="item.name">{{ item.name }}</option>
            </select>
            от <input @change="inputTrigger" type="date" v-model="dateContract" /> за переделы Российской Федерации на территории государств : </p>
          <p><input @change="inputTrigger" v-model="states" type="text" placeholder="Список государств"></p>
          <p>с <input @change="inputTrigger" type="date" v-model="startPeriod" /> по <input @change="inputTrigger" type="date" v-model="endPeriod" /> в связи</p>
          <p><input @change="inputTrigger" v-model="cause" type="text" placeholder="Указать причину"></p>
          <p>, и оформить расширение территории покрытия полиса КАСКО. </p>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
export default {
  name: 'Template3',
  data () {
    return {
      selectContract: '',
      dateContract: '',
      allContracts: [],
      cause: '',
      states: '',
      startPeriod: '',
      endPeriod: '',
      loadingData: true
    }
  },
  beforeDestroy () {
    this.$store.state.faqMessage = ''
  },
  methods: {
    inputTrigger () {
      const result = `
        Прошу предоставить согласие на временное перемещение Предмета лизинга по Договору лизинга № ${this.selectContract} ${this.dateContract} за переделы Российской Федерации на территории государств : ${this.states} с ${this.startPeriod} по ${this.endPeriod}   в связи ${this.cause}, и оформить расширение территории покрытия полиса КАСКО. 
      `
      this.$store.state.faqMessage = result
    },
    checkInputs () {
      if (this.selectContract && this.dateContract && this.cause && this.states && this.startPeriod && this.endPeriod) return true
      else return false
    },
    doneLoadingContracts ({ data }) {
      this.allContracts = data.allContracts
      this.loadingData = false
    }
  },
  computed: {
    selectContractModel: {
      get () {
        return this.selectContract
      },
      set (value) {
        this.selectContract = value
        this.allContracts.map(elem => {
          if (elem.name === value) this.dateContract = elem.date.split('T')[0]
        })
      }
    }
  }
}
</script>

<style lang="stylus">
  .template-form {
    display inline-block
    white-space pre-wrap

  > p {
      > input[type='date'] {
        width 150px !important
      }
      > input[type='number'] {
        width 60px !important
      }
      > .base {
        width 120px !important
      }
    }
  }
</style>
