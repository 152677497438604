<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllContractsLight.gql')"
    :variables="{}"
    @result="doneLoadingContracts"
  >
    <template slot-scope="{ result: { error, data } }" class="template-form">
      <div v-if="loadingData && !error" class="messages__status loading">Идёт загрузка данных...</div>
      <div v-if="error" class="messages__status error">Ошибка. Обратитесь в тех.поддержку</div>
      <div v-if="!error && !loadingData" class="template-form">
        <p>Прошу ООО «Лизинг-Трейд» рассмотреть возможность замены Лизингополучателя по Договору лизинга №
          <select @change="inputTrigger" v-model="selectContractModel" id="contract">
            <option v-for="item in data.allContracts" :key="item.name" :value="item.name">{{ item.name }}</option>
          </select>
          от <input @change="inputTrigger" type="date" v-model="dateContract" /> в связи с</p>
        <p><input @change="inputTrigger" v-model="cause" type="text" placeholder="Указать причину"></p>
        <p>Данные Нового лизингополучателя:</p>
        <p>Юридический адрес: <input @change="inputTrigger" v-model="uAddress" type="text"></p>
        <p>Фактический адрес: <input @change="inputTrigger" v-model="fAddress" type="text"></p>
        <p>ИНН/КПП: <input @change="inputTrigger" v-model="innKpp" type="text"></p>
        <p>Расчетный счет: <input @change="inputTrigger" v-model="rs" type="text"></p>
        <p>Банк: <input @change="inputTrigger" v-model="bank" type="text"></p>
        <p>Кор.счет: <input @change="inputTrigger" v-model="ks" type="text"></p>
        <p>БИК: <input @change="inputTrigger" v-model="bik" type="text"></p>
        <p>Генеральный директор: <input @change="inputTrigger" v-model="gen" type="text"></p>
        <p>Контактный тел.: <input @change="inputTrigger" v-model="phone" type="tel"></p>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
export default {
  name: 'Template6',
  data () {
    return {
      selectContract: '',
      dateContract: '',
      allContracts: [],
      cause: '',
      uAddress: '',
      fAddress: '',
      innKpp: '',
      rs: '',
      bank: '',
      ks: '',
      bik: '',
      gen: '',
      phone: '',
      loadingData: true
    }
  },
  beforeDestroy () {
    this.$store.state.faqMessage = ''
  },
  methods: {
    inputTrigger () {
      const result = `
        Прошу ООО «Лизинг-Трейд» рассмотреть возможность замены Лизингополучателя по Договору лизинга № ${this.selectContract} от ${this.dateContract} в связи с ${this.cause}(подробное описание причины необходимости замены Лизингополучателя)

        Данные Нового лизингополучателя:
        Юридический адрес: ${this.uAddress}
        Фактический адрес: ${this.fAddress}
        ИНН/КПП: ${this.innKpp}
        Расчетный счет: ${this.rs}
        Банк: ${this.bank}
        Кор.счет: ${this.ks}
        БИК: ${this.bik}
        Генеральный директор: ${this.gen}
        Контактный тел.: ${this.phone}
      `
      this.$store.state.faqMessage = result
    },
    checkInputs () {
      if (this.selectContract && this.dateContract && this.cause && this.uAddress && this.fAddress && this.innKpp && this.rs && this.bank && this.ks && this.bik && this.gen && this.phone) return true
      else return false
    },
    doneLoadingContracts ({ data }) {
      this.allContracts = data.allContracts
      this.loadingData = false
    }
  },
  computed: {
    selectContractModel: {
      get () {
        return this.selectContract
      },
      set (value) {
        this.selectContract = value
        this.allContracts.map(elem => {
          if (elem.name === value) this.dateContract = elem.date.split('T')[0]
        })
      }
    }
  }
}
</script>

<style lang="stylus">
  .template-form {
    display inline-block
    white-space pre-wrap

  > p {
      > input[type='date'] {
        width 150px !important
      }
      > input[type='number'] {
        width 60px !important
      }
      > .base {
        width 120px !important
      }
    }
  }
</style>
